<template>
  <Content :dev='true'></Content>
</template>

<script>
import Content from '../knowledge-points/Content.vue'
export default {
  components: {
    Content
  }
}
</script>

<style>
</style>
